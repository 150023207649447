import * as React from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { useLanguage } from "../../../languageContext"
import Box from "@mui/material/Box"

function createData(name: string, anbieter: string, zweck: string, ablauf: string, typ: string) {
  return { name, anbieter, zweck, ablauf, typ }
}

export default function TextTable1() {
  const { t } = useLanguage()

  const rows = [createData("fe_typo_user", "www.appmodule.net", t("privacy3Table1"), "Session", "HTTP Cookie")]

  return (
    <TableContainer component={Paper} sx={{ marginBottom: "20px" }}>
      <Box sx={{ overflow: "auto" }}>
        <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{ backgroundColor: "#E3E3E3" }}>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>{t("privacy3TableHeading2")}</TableCell>
                <TableCell>{t("privacy3TableHeading3")}</TableCell>
                <TableCell>{t("privacy3TableHeading4")}</TableCell>
                <TableCell>{t("privacy3TableHeading5")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.name} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell>{row.anbieter}</TableCell>
                  <TableCell>{row.zweck}</TableCell>
                  <TableCell>{row.ablauf}</TableCell>
                  <TableCell>{row.typ}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Box>
    </TableContainer>
  )
}
