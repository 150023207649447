import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { ThemeProvider } from "@emotion/react"
import { AppTheme } from "./AppTheme"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { LanguageProvider } from "./languageContext"

export const queryClient = new QueryClient()

// fetch("/settings.json")
//   .then(async (res) => {
//     const settings = await res.json()
//     localStorage.setItem("settings", JSON.stringify(settings))
//     return settings
//   })
//   .then(() => {
    ReactDOM.createRoot(document.getElementById("root")!).render(
      <QueryClientProvider client={queryClient}>
        <React.StrictMode>
          <ThemeProvider theme={AppTheme}>
            <LanguageProvider>
              <App />
            </LanguageProvider>
          </ThemeProvider>
        </React.StrictMode>
      </QueryClientProvider>
    )
  // })

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
