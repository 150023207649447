import React from "react"
import { createBrowserRouter, Navigate, Outlet, RouterProvider } from "react-router-dom"
import Navigation from "../components/advanced/navigation/Navigation"
import HomePage from "../pages/HomePage"
import ScrollToTop from "../ScrollToTop"
import ServicePage from "../pages/ServicePage"
import PrivacyPolicyPage from "../pages/PrivacyPolicyPage"

const allRoutes = [
  {
    path: "services",
    element: (
      <ScrollToTop>
        <Outlet />
      </ScrollToTop>
    ),
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: "nodeRed",
        element: <ServicePage />,
      },
      {
        path: "influxDB",
        element: <ServicePage />,
      },
      {
        path: "keycloak",
        element: <ServicePage />,
      },
      {
        path: "mongoDB",
        element: <ServicePage />,
      },
      {
        path: "swormGuard",
        element: <ServicePage />,
      },
      {
        path: "SIOT",
        element: <ServicePage />,
      },
    ],
  },
  {
    path: "privacy-policy",
    element: <PrivacyPolicyPage  />,
  },
  {
    path: "*",
    element: <Navigate replace to={"/services"} />,
  },
  {
    path: "/",
    element: <Navigate to="/services" replace={true} />,
  },
]

export default function AppRouter() {
  const router = React.useMemo(() => {
    return createBrowserRouter([
      {
        path: "/",
        element: <Navigation />,
        children: [...allRoutes],
      },
    ])
  }, [])

  return <RouterProvider router={router} />
}
