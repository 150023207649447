import React, { Fragment, useState } from "react"
import Box from "@mui/material/Box"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import Typography from "@mui/material/Typography"
import { useLanguage } from "../../../languageContext"
import MenuIcon from '@mui/icons-material/Menu';
import GridItem from '../grid/GridItem'
import { routesAppBar } from "../../advanced/navigation/Routes"
import { HashLink } from "react-router-hash-link"
import { Link, useLocation } from "react-router-dom"
import SupportMenuMobile from './SupportMenuMobile'

interface Route {
  name: string
  route?: string
  subitems?: Subitem[]
}

interface Subitem {
  name: string
  route: string
}

const styles = {
  iconText: {
    width: "115px",
    height: "42px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#666666",
  },
  menuItem: {
    color: "#346ea9",
    fontWeight: "bold",
    "&:hover": {
      background: "#346ea9",
      color: "white",
    },
  },
  menu: {
    "& .MuiPaper-root": {
      width: "60px",
    },
  },
  notificationBadge: {
    ml: "auto",
    mr: "16px",
  },
  button: {
    textTransform: "none",
    fontWeight: "bold",
    height: "32px",
    ml: "auto",
    mr: "25px",
    borderRadius: "100px",
    borderColor: "#346ea9",
    color: "#346ea9",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "#346ea9",
      transition: ".4s ease",
      color: "white",
    },
  },
  flag: {
    width: 10,
    mr: 2,
    cursor: "pointer",
  },
  iconHolderActive1: {
    borderRadius: "100%",
    width: "42px",
    height: "42px",
    display: "flex",
    mr: { xs: "10px", sm: "25px", md: "25px", lg: "25px", xl: "25px" },
    ml: "auto",
    "&:hover": {
      background: "#e6f2ff",
    },
    cursor: "pointer",
  },
  icon: {
    width: "42px",
    height: "42px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#666666",
  },
  linksToken: {
    gap: "10px",
    // height: "75px",
    display: { xs: "flex", sm: "flex", md: "flex", lg: "flex", xl: "none" },
    justifyContent: "center",
    alignItems: "center",
    ml: "20px",
    color: "#666666",
  },
  links: {
    gap: "10px",
    // height: "75px",
    display: { xs: "flex", sm: "flex", md: "flex", lg: "none", xl: "none" },
    justifyContent: "center",
    alignItems: "center",
    marginX: "auto",
    color: "#666666",
  },
  linkHolderActive: {
    textAlign: "center",
    height: "34px",
    mt: "6px",
    backgroundColor: "#e6f2ff",
    padding: "5px 15px",
    "&:hover": {
      backgroundColor: "#e6f2ff",
      color: "black",
    },
  },
  linkHolder: {
    textAlign: "center",
    height: "34px",
    mt: "6px",
    borderRadius: "25px",
    padding: "5px 15px",
    "&:hover": {
      backgroundColor: "#e6f2ff",
      color: "black",
    },
  },
}

export default function TopMenu() {
  const location = useLocation()
  const { t } = useLanguage()
  const token = localStorage.getItem("token")
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const isActiveRoute = (route: string) => {
    return location.pathname === route
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Fragment>
      <Box sx={{ display: { xs: "flex", sm: "flex", md: "flex", lg: token ? "flex" : "none", xl: "none" }, alignItems: "center", textAlign: "center" }}>
        <GridItem sx={styles.iconHolderActive1}>
          <GridItem sx={styles.icon} onClick={handleClick}>
            <MenuIcon />
          </GridItem>
        </GridItem>
      </Box>

      <Menu
        sx={token ? styles.linksToken : styles.links} component="div"
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        // sx={styles.menu}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {routesAppBar.map((route: Route, index: number) =>
          route.route !== undefined ? (
            route.route === "/home#scenarios" || route.route === "/home#success-components" || route.route === "/home#your-benefits" ? (
              <MenuItem
                component={HashLink}
                key={index}
                smooth
                to={route.route}
                sx={isActiveRoute(route.route) ? styles.linkHolderActive : styles.linkHolder}
              >
                {t(route.name)}
              </MenuItem>
            ) : (
              <MenuItem
                component={Link}
                to={route.route}
                key={index}
                sx={isActiveRoute(route.route) ? styles.linkHolderActive : styles.linkHolder}
              >
                <Typography sx={isActiveRoute(route.route) ? { color: "black" } : {}} variant="body2">
                  {t(route.name)}
                </Typography>


              </MenuItem>
            )
          ) : (
            <GridItem sx={styles.menu} key={index}>
              <SupportMenuMobile subitemRoutes={route.subitems} />
            </GridItem>
          )
        )}
      </Menu>

    </Fragment>
  )
}
