import React, { useEffect, useState } from "react"
import { Outlet } from "react-router-dom"
import { styled } from "@mui/material/styles"
import Box from "@mui/material/Box"
import CssBaseline from "@mui/material/CssBaseline"
import AppBar from "../appBar/AppBar"
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar"
import Agreement from "../../basic/agreement/Agreement"

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const styles = {
  drawer: {
    height: "100%",
    display: "flex",
  },
  iconButton: {
    ml: "auto",
    mr: "8px",
    color: "white",
    background: '"#454b54"',
    "&:hover": {
      backgroundColor: "#454b54",
      transition: ".4s ease",
    },
  },
  drawerHeader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  activeRoute: {
    backgroundColor: "#454b54",
    color: "white",
    "&:hover": {
      backgroundColor: "#454b54",
      transition: ".4s ease",
      color: "white",
    },
  },
  activeSubRoute: {
    backgroundColor: "#454b54",
    color: "white",
    paddingLeft: 12,
    "&:hover": {
      backgroundColor: "#454b54",
      transition: ".4s ease",
      color: "white",
    },
  },
  route: {
    color: "white",
    "&:hover": {
      backgroundColor: "#454b54",
      transition: ".4s ease",
      color: "white",
    },
  },
  subRoute: {
    paddingLeft: 12,
    color: "white",
    "&:hover": {
      backgroundColor: "#454b54",
      transition: ".4s ease",
      color: "white",
    },
  },
  appBar: {
    height: "75px",
    fontSize: "15px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
    background: "#b5cbe8",
  },
  scrolledAppBar: {
    height: "75px",
    fontSize: "15px",
    backgroundColor: "rgba(255, 255, 255, 0.6)",
    backdropFilter: "blur(10px)",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
    background: "#b5cbe8",
  },
}

const cookies = localStorage.getItem("cookies")

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  color: "white",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}))

const Appbar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.shorter,
  }),
  width: "100%",
  background: "#ffffff",
}))

type Anchor = "top" | "left" | "bottom" | "right"

const Navigation = () => {
  const [isScrolled, setIsScrolled] = useState(false)
  const [agreementModal, setAgreementModal] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY
      setIsScrolled(scrollPosition > 20)
    }
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event && event.type === "keydown" && ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")) {
      return
    }
    setAgreementModal({ ...agreementModal, [anchor]: open })
    localStorage.setItem("cookies", "true")
  }

  useEffect(() => {
    setAgreementModal({ ...agreementModal, bottom: true })
    // eslint-disable-next-line
  }, [])

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Appbar sx={isScrolled ? styles.scrolledAppBar : styles.appBar} elevation={0}>
        <AppBar />
      </Appbar>
      <Box component="main" sx={{ flexGrow: 1, height: "100vh", pt: 1.5 }}>
        <DrawerHeader />
        <Outlet />
        {!cookies && <Agreement state={agreementModal} toggleDrawer={toggleDrawer} />}
      </Box>
    </Box>
  )
}

export default Navigation
