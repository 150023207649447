import React, { useEffect, useState } from "react"
import GridItem from "../components/basic/grid/GridItem"
import ContainedButton from "../components/basic/buttons/ContainedButton"
import { Button, Typography } from "@mui/material"
import headerBgShape from "../assets/images/header-bg-shape.png"
import { useLocation, useNavigate } from "react-router-dom"
import headerImage from "../assets/images/lada1.jpg"
import nodeRedImage from "../assets/images/NodeRed.webp"
import influxImage from "../assets/images/InfluxDB.webp"
import mongoImage from "../assets/images/mongoDb.png"
import keycloakImage from "../assets/images/keycloak.png"
import siotImage from "../assets/images/smartCity.png"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import nodeRedIcon from "../assets/icons/node-red-icon.png"
import influxDBIcon from "../assets/icons/influx-db-icon.png"
import mongoDBIcon from "../assets/icons/mongo-db-icon.png"
import keycloakIcon from "../assets/icons/keycloak-icon.png"
import swormGuardIcon from "../assets/icons/secure-guard-icon.png"
import SIOTIcon from "../assets/logos/siot_logo.png"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { SubserviceItem } from "../repository/PriceListRepository"
import priceList from "../repository/priceList.json"

const styles = {
  main: {
    display: "flex",
    backgroundImage: `url(${headerBgShape})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center bottom",
    backgroundAttachment: "fixed",
    height: "90%",
  },
  container: {
    width: { xs: "90%", sm: "85%", md: "80%", lg: "80%", xl: "80%" },
    maxWidth: { xs: "90%", sm: "85%", md: "80%", lg: "80%", xl: 1300 },
    mb: "15px",
    flexGrow: "0",
    gap: "4%",
    margin: "70px auto",
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: { xs: "column", sm: "column", md: "row", lg: "row", xl: "row" },
    gap: "6%",
    flex: 1,
  },
  item1: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: { xs: "100%", sm: "100%", md: "50%", lg: "50%", xl: "50%" },
    height: "auto",
    mb: "auto",
    pb: "20px",
  },
  item2: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: { xs: "100%", sm: "100%", md: "50%", lg: "50%", xl: "50%" },
    mb: "auto",
    pb: "20px",
  },
  icon: {
    mt: "10px",
  },
  title: {
    mt: { xs: "13px", sm: "5px", md: "0px", lg: "0px", xl: "0px" },
    ml: "15px",
    fontSize: { xs: "33px", sm: "40px", md: "45px", lg: "45px", xl: "45px" },
  },
  button: {
    textTransform: "none",
    fontWeight: "bold",
    height: "42px",
    fontSize: "18px",
    ml: "auto",
    mb: "60px",
    borderRadius: "10px",
    borderColor: "#346ea9",
    color: "gray",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
      transition: ".4s ease",
      color: "gray",
    },
  },
  buttonTry: {
    textTransform: "none",
    fontWeight: "bold",
    height: "32px",
    ml: "auto",
    mr: "25px",
    borderRadius: "10px",
    borderColor: "#346ea9",
    color: "#346ea9",
    mt: "15px",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "#346ea9",
      transition: ".4s ease",
      color: "white",
    },
  },
  acordion: {
    mt: "20px",
    width: "100%",
    backgroundColor: "#e8f2ff",
    "&:hover": {
      backgroundColor: "#e8f2ff",
      transition: ".4s ease",
    },
  },
  prices: {
    width: "100%",
    display: "flex",
    mt: "10px",
  },
  price: {
    mr: "5px",
    fontSize: { xs: "18px", sm: "20px", md: "20px", lg: "20px", xl: "20px" },
    fontWeight: "bold",
    mt: "-3px",
  },
  price1: {
    mr: "5px",
    fontSize: { xs: "16px", sm: "18px", md: "18px", lg: "18px", xl: "18px" },
  },
}

const mapUrl = (url: string) => {
  if (url === "Node Red") return "nodeRed"
  if (url === "Influx DB") return "influxDB"
  if (url === "Keycloak") return "keycloak"
  if (url === "Mongo DB") return "mongoDB"
  if (url === "Sworm Guard") return "swormGuard"
  if (url === "SIOT") return "SIOT"
}

const ServicePage = () => {
  const [serviceData, setServiceData] = useState<any>()
  const [expanded, setExpanded] = useState<string | false>(false)

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  const location = useLocation()
  const navigate = useNavigate()
  const path = location.pathname
  const pathSegments = path.split("/services/")
  const service = pathSegments[1]

  const mapIcons = () => {
    if (service === "nodeRed") return nodeRedIcon
    if (service === "influxDB") return influxDBIcon
    if (service === "keycloak") return keycloakIcon
    if (service === "mongoDB") return mongoDBIcon
    if (service === "swormGuard") return swormGuardIcon
    if (service === "SIOT") return SIOTIcon
  }

  const mapImages = () => {
    if (service === "nodeRed") return nodeRedImage
    if (service === "influxDB") return influxImage
    if (service === "keycloak") return keycloakImage
    if (service === "mongoDB") return mongoImage
    if (service === "swormGuard") return headerImage
    if (service === "SIOT") return siotImage
  }

  useEffect(() => {
    setServiceData(priceList?.services.filter((item: any) => mapUrl(item.name) === service)[0])
  }, [service])

  return (
    <GridItem sx={styles.main}>
      <GridItem sx={styles.container}>
        <ContainedButton onClick={() => navigate("/services")} sx={styles.button}>
          <ArrowBackIcon sx={{ mr: "5px" }} />
          back
        </ContainedButton>
        <GridItem sx={styles.flex}>
          <GridItem sx={styles.item1}>
            <GridItem sx={{ display: "flex", mb: "10px" }}>
              <GridItem sx={styles.icon}>
                <img
                  src={mapIcons()}
                  alt="Mode Red"
                  loading="lazy"
                  style={{ objectFit: "contain", maxWidth: "100%", maxHeight: "100%", borderRadius: "5px" }}
                />
              </GridItem>
              <Typography sx={styles.title}>{serviceData && serviceData.name}</Typography>
            </GridItem>
            <Typography variant="body1">{serviceData?.description}</Typography>
            {serviceData?.subservices !== undefined ? (
              serviceData?.subservices.map((item: SubserviceItem) => (
                <Accordion sx={styles.acordion} expanded={expanded === item.name} key={item.name} onChange={handleChange(item.name)}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                      {item.name}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>{item.description}</Typography>
                    <GridItem sx={styles.prices}>
                      <Typography sx={styles.price}>Price: </Typography>
                  <Typography sx={styles.price1}>{item?.price?.chf.price}</Typography>
                  <Typography sx={styles.price1}>{item?.price?.chf.currency},</Typography>
                  <Typography sx={styles.price1}>{item?.price?.usd.price}</Typography>
                  <Typography sx={styles.price1}>{item?.price?.usd.currency},</Typography>
                  <Typography sx={styles.price1}>{item?.price?.euro.price}</Typography>
                  <Typography sx={styles.price1}>{item?.price?.euro.currency}</Typography>
                    </GridItem>
                    <Button variant="outlined" onClick={() => navigate("/services")} sx={styles.buttonTry}>
                      Try
                    </Button>
                  </AccordionDetails>
                </Accordion>
              ))
            ) : (
              <GridItem>
                <GridItem sx={styles.prices}>
                  <Typography sx={styles.price}>Price: </Typography>
                  <Typography sx={styles.price1}>{serviceData?.price?.chf.price}</Typography>
                  <Typography sx={styles.price1}>{serviceData?.price?.chf.currency},</Typography>
                  <Typography sx={styles.price1}>{serviceData?.price?.usd.price}</Typography>
                  <Typography sx={styles.price1}>{serviceData?.price?.usd.currency},</Typography>
                  <Typography sx={styles.price1}>{serviceData?.price?.euro.price}</Typography>
                  <Typography sx={styles.price1}>{serviceData?.price?.euro.currency}</Typography>
                </GridItem>
                <Button variant="outlined" onClick={() => navigate("/services")} sx={styles.buttonTry}>
                  Try
                </Button>
              </GridItem>
            )}
          </GridItem>
          <GridItem sx={styles.item2}>
            <img
              src={mapImages()}
              alt="Wired Robot"
              loading="lazy"
              style={{ objectFit: "contain", width: "100%", height: "100%", borderRadius: "15px" }}
            />
          </GridItem>
        </GridItem>
      </GridItem>
    </GridItem>
  )
}
export default ServicePage
