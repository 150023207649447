import React  from "react"
import { ServiceItem } from "../repository/PriceListRepository"
import { useNavigate } from "react-router-dom"
import { keyframes } from "@emotion/react"
import { Button, Typography } from "@mui/material"
import GridContainer from "../components/basic/grid/GridContainer"
import GridItem from "../components/basic/grid/GridItem"
import headerImage from "../assets/images/header-image.png"
import headerBgShape from "../assets/images/header-bg-shape.png"
import NodeRedIcon from "../assets/icons/node-red-icon.png"
import InfluxDBIcon from "../assets/icons/influx-db-icon.png"
import MongoDBIcon from "../assets/icons/mongo-db-icon.png"
import KeycloakIcon from "../assets/icons/keycloak-icon.png"
import SwormGuardIcon from "../assets/icons/secure-guard-icon.png"
import SIOTIcon from "../assets/logos/siot_logo.png"
import priceList from "../repository/priceList.json"

const scale = keyframes`
0% {
  opacity: 0;
  transform: scale(0);
}
50% {
  transform: scale(1);
  opacity: 1;
}
80% {
  transform: scale(1.5);
  opacity: 1;
}
100% {
  opacity: 0;
  transform: scale(2);
}
`

const styles = {
  container: {
    width: { xs: "90%", sm: "85%", md: "80%", lg: "80%", xl: "80%" },
    maxWidth: { xs: "90%", sm: "85%", md: "80%", lg: "80%", xl: 1300 },
    mb: "15px",
    flexGrow: "0",
    gap: "6%",
    margin: "0 auto",
  },
  containerFull: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    justifyContent: "center",
    pl: { xs: "15px", sm: "15px", md: "0", lg: "0", xl: "0" },
    pr: { xs: "15px", sm: "15px", md: "0", lg: "0", xl: "0" },
  },
  header: {
    backgroundImage: `url(${headerBgShape})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center bottom",
    borderBottom: "1px solid #eeeeee",
  },
  container1070: {
    width: { xs: "90%", sm: "85%", md: "80%", lg: "80%", xl: "80%" },
    maxWidth: { xs: "90%", sm: "85%", md: "80%", lg: "80%", xl: 1300 },
    margin: "0 auto",
    mb: "15px",
    flex: 1,
    flexWrap: "wrap",
    justifyContent: "left",
    alignItems: "center",
  },
  container1070_3: {
    width: "100%",
    maxWidth: "100%",
    mb: "15px",
    justifyContent: { xs: "center", sm: "center", md: "center", lg: "right", xl: "right" },
    display: "flex",
    alignItems: "center",
  },
  item50: {
    flexBasis: { xs: "100%", sm: "100%", md: "500%", lg: "47%", xl: "47%" },
    flexWrap: "wrap",
    justifyContent: "left",
    alignItems: "center",
    flexDirection: "row",
  },
  sectionHeading: {
    fontSize: { xs: 24, sm: 24, md: 32, lg: 38, xl: 38 },
    fontWeight: "bold",
    mb: "30px",
  },
  mainHeading: {
    fontSize: { xs: 38, sm: 38, md: 50, lg: 80, xl: 80 },
  },
  itemHeading: {
    width: { xs: "90%", sm: "85%", md: "80%", lg: "40%", xl: "37%" },
    maxWidth: { xs: "90%", sm: "85%", md: "80%", lg: "40%", xl: "37%" },
    textAlign: { xs: "center", sm: "center", md: "center", lg: "left", xl: "left" },
    marginTop: { xs: "40px", sm: "40px", md: "40px", lg: "10px", xl: "10px" },
  },
  text: {
    fontSize: { xs: 16, sm: 16, md: 19, lg: 19, xl: 19 },
    lineHeight: { xs: 1.5, sm: 1.5, md: 2, lg: 2, xl: 2 },
  },
  item2: {
    flex: 1,
    maxWidth: "100%",
    // maxHeight: { xs: 250, sm: 250, md: 350, lg: 350, xl: 400 },
    maxHeight: "fit-content",
    paddingTop: "20px",
    width: { xs: "90%", sm: "400px", md: "470px", lg: "100%", xl: "100%" },
    margin: { xs: "0 auto", sm: "0 auto", md: "0 auto", lg: "0", xl: "0" },
  },
  containerImageRow: {
    width: "100%",
    maxWidth: "100%",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
  serviceItem: {
    height: "242px;",
    borderRadius: "15px",
    mb: "30px",
    maxWidth: { xs: 360, sm: 360, md: 270, lg: 305, xl: 360 },
    width: { xs: "100%", sm: 550, md: 700, lg: 320, xl: 360 },
    margin: "10px auto",
    boxShadow: "0 0 15px 15px #dddddd",
    padding: "35px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignContent: "space-between",
    transition: ".4s ease",
    "&:hover": {
      transition: ".8s ease",
      boxShadow: "0 0 15px 15px #bbbbbb",
      backgroundColor: "#f5f5f5",
    },
  },
  iconAndText: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
    mt: "-7px",
    borderRadius: "15px 15px 0 0",
  },
  textIcon: {
    color: "white",
    fontSize: "11px",
    mr: "20px",
  },
  imageIconText: {
    fontSize: "24px",
    fontWeight: "bold",
  },
  serviceItemHeading: {
    width: "100%",
    objectFit: "contain",
  },
  serviceItemTextHolder: {
    textAlign: "left",
  },
  serviceItemText: {
    fontSize: "18px",
  },
  serviceItemLinkHolder: {
    textAlign: "left",
  },
  serviceItemLink: {
    fontSize: "16px",
    color: "#346EA9",
    fontWeight: "bold",
    textDecoration: "none",
    transition: ".4s ease",
    cursor: "pointer",
    "&:hover": {
      transition: ".4s ease",
      marginLeft: "5px",
    },
  },
  button: {
    textTransform: "none",
    fontWeight: "bold",
    height: "42px",
    fontSize: "18px",
    ml: "auto",
    mb: "60px",
    borderRadius: "10px",
    borderColor: "#346ea9",
    color: "gray",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
      transition: ".4s ease",
      color: "gray",
    },
  },
  animationHolder: {
    position: "relative",
  },
  animation: {
    width: "15px",
    height: "15px",
    borderRadius: "50%",
    backgroundColor: "#fd6a12",
    position: "absolute",
    left: "50%",
    bottom: "0",
    "&:before": {
      content: '""',
      width: "100%",
      height: "100%",
      position: "absolute",
      left: "0",
      top: "0",
      backgroundColor: "rgba(253,106,18,.5)",
      animation: `${scale} 1s linear infinite`,
      borderRadius: "50%",
    },
  },
}

const HomePage = () => {
  const navigate = useNavigate()

  let services: ServiceItem[] | any = priceList?.services

  if (services) {
    //console.log(services)
    services = services?.map((service: ServiceItem) => {
      let res = service
      if (service.subservices) {
        res = service.subservices[0]
        res.name = service.name
      }
      return res
    })
  }

  const iconMap: Record<string, string> = {
    'NodeRedIcon': NodeRedIcon,
    'InfluxDBIcon': InfluxDBIcon,
    'MongoDBIcon': MongoDBIcon,
    'KeycloakIcon': KeycloakIcon,
    'SwormGuardIcon': SwormGuardIcon,
    'SIOTIcon': SIOTIcon
  }

  const mapUrl = (url: string) => {
    if (url === "Node Red") return "nodeRed"
    if (url === "Influx DB") return "influxDB"
    if (url === "Keycloak") return "keycloak"
    if (url === "Mongo DB") return "mongoDB"
    if (url === "Sworm Guard") return "swormGuard"
    if (url === "SIOT") return "SIOT"
  }

  return (
    <>
      <GridContainer sx={[styles.containerFull, styles.header]}>
        <GridContainer sx={[styles.container1070_3]}>
          <GridItem sx={[styles.item50, styles.itemHeading]}>
            <Typography variant="body2" sx={[styles.sectionHeading, styles.mainHeading]}>
              ioTools
            </Typography>
            <Typography variant="body2" sx={styles.text}>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever
              since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
            </Typography>
            <GridItem style={{ marginTop: "20px" }}>
              <Button
                variant="outlined"
                onClick={() => navigate("/meeting")}
                sx={styles.button}
                style={{ marginRight: "20px", color: "#ffffff", backgroundColor: "#346ea9" }}
              >
                Get Started
              </Button>
              <Button variant="outlined" onClick={() => navigate("/meeting")} sx={styles.button}>
                Start a free trial
              </Button>
            </GridItem>
            <GridItem sx={styles.animationHolder}>
              <GridItem sx={styles.animation}></GridItem>
            </GridItem>
          </GridItem>
          <GridItem sx={styles.item50}>
            <GridItem sx={styles.item2}>
              <img
                src={headerImage}
                alt="Wired Robot"
                loading="lazy"
                style={{
                  objectFit: "contain",
                  maxWidth: "100%",
                  maxHeight: "100%",
                }}
              />
            </GridItem>
          </GridItem>
        </GridContainer>
      </GridContainer>

      <GridContainer sx={[styles.containerFull]}>
        <GridContainer sx={[styles.container1070]} style={{ textAlign: "center", display: "block", marginTop: "40px" }}>
          <Typography variant="body2" sx={styles.sectionHeading}>
            All our services
          </Typography>
          <GridContainer sx={[styles.containerImageRow]}>
            {services &&
              services.map((element: ServiceItem) => (
                <GridItem key={element.name} sx={styles.serviceItem}>
                  <GridItem sx={styles.iconAndText}>
                    <GridItem sx={styles.textIcon}>
                      <img
                        src={iconMap[element.name.replace(" ", "") + "Icon"]}
                        alt={element.name}
                        loading="lazy"
                        style={{ objectFit: "contain", maxWidth: "100%", maxHeight: "100%", borderRadius: "5px" }}
                      />
                    </GridItem>
                    <Typography variant="body2" sx={styles.imageIconText}>
                      {element.name}
                    </Typography>
                  </GridItem>
                  <GridItem sx={styles.serviceItemTextHolder}>
                    <Typography variant="h6" sx={styles.serviceItemText}>
                      {element.description?.substring(0, 60)}...
                    </Typography>
                  </GridItem>
                  <GridItem sx={styles.serviceItemLinkHolder}>
                    <GridItem sx={styles.serviceItemLink} onClick={() => navigate(`${mapUrl(element.name)}`)}>
                      Discover more &gt;
                    </GridItem>
                  </GridItem>
                </GridItem>
              ))}
          </GridContainer>
        </GridContainer>
      </GridContainer>
    </>
  )
}
export default HomePage
