import GridItem from "../../basic/grid/GridItem"
import GridContainer from "../../basic/grid/GridContainer"
import { routesAppBar } from "../navigation/Routes"
import { List, ListItemButton, ListItemText, Typography } from "@mui/material"
import { Link, useNavigate } from "react-router-dom"
import SupportMenu from "../../basic/menu/SupportMenu"
import { ReactComponent as AppmoduleLogo } from "../../../assets/logos/appmodule-text.svg"
import LanguageMenu from "../../basic/menu/LanguageMenu"
import { useLanguage } from "../../../languageContext"
import TopMenu from "../../basic/menu/TopMenu"

interface Route {
  name: string
  route?: string
  subitems?: Subitem[]
}

interface Subitem {
  name: string
  route: string
}

const styles = {
  linksToken: {
    gap: "10px",
    height: "75px",
    display: { xs: "none", sm: "none", md: "none", lg: "none", xl: "flex" },
    justifyContent: "center",
    alignItems: "center",
    ml: "20px",
    color: "#666666",
  },
  links: {
    gap: "10px",
    height: "75px",
    display: { xs: "none", sm: "none", md: "none", lg: "flex", xl: "flex" },
    justifyContent: "center",
    alignItems: "center",
    marginX: "auto",
    color: "#666666",
  },
  linkHolderActive: {
    textAlign: "center",
    height: "34px",
    mt: "6px",
    backgroundColor: "#346ea9",
    borderRadius: "25px",
    padding: "5px 15px",
    "&:hover": {
      backgroundColor: "#e6f2ff",
      color: "black",
    },
  },
  linkHolder: {
    textAlign: "center",
    height: "34px",
    mt: "6px",
    borderRadius: "25px",
    padding: "5px 15px",
    color: "#284263",
    "&:hover": {
      backgroundColor: "#346ea9",
      transition: ".4s ease",
      color: "white",
    },
  },
  iconHolderActive1: {
    borderRadius: "100%",
    width: "42px",
    height: "42px",
    display: "flex",
    mr: "22px",
    ml: "auto",
    "&:hover": {
      background: "#e6f2ff",
    },
    cursor: "pointer",
  },
  icon: {
    width: "42px",
    height: "42px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#666666",
  },
  menu: {
    mt: "6px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#666666",
    padding: "5px 0px",
  },
  icons: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    //ml: "auto",
    height: "75px",
  },
  icons1: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    ml: "auto",
    mr: "25px",
    height: "75px",
  },
  button: {
    display: { xs: "none", sm: "flex", md: "flex", lg: "flex", xl: "flex" },
    textTransform: "none",
    fontWeight: "bold",
    height: "32px",
    ml: "auto",
    mr: "25px",
    borderRadius: "100px",
    borderColor: "#346ea9",
    color: "#346ea9",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "#346ea9",
      transition: ".4s ease",
      color: "white",
    },
  },
  flag: {
    width: 10,
    mr: 2,
    cursor: "pointer",
  },
  iconButton: {
    mr: "auto",
    ml: "10px",
  },
}

const AppBar = () => {
  const { t } = useLanguage()
  const navigate = useNavigate()

  return (
    <GridItem
      sx={{
        height: "75px",
        width: { xs: "90%", sm: "85%", md: "80%", lg: "80%", xl: "80%" },
        maxWidth: { xs: "90%", sm: "85%", md: "80%", lg: "80%", xl: 1300 },
        pl: { xs: "25px", sm: "0", md: "0", lg: "0", xl: "0" },
        pr: { xs: "25px", sm: "0", md: "0", lg: "0", xl: "0" },
        marginX: "auto",
      }}
    >
      <GridContainer style={{ justifyContent: "space-between" }}>
        <GridItem
          sx={{
            width: { xs: "100px", sm: "150px", md: "150px", lg: "150px", xl: "150px" },
            cursor: "pointer",
            //mt: "13px"
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => navigate("/services")}
        >
          <AppmoduleLogo  />
        </GridItem>
        <List sx={styles.links} component="div" disablePadding>
          {routesAppBar.map((route: Route, index: number) =>
            route.route !== undefined ? (
                <ListItemButton
                  component={Link}
                  to={route.route}
                  key={index}
                  sx={styles.linkHolder}
                >
                  <ListItemText
                    primary={
                      <Typography variant="body2">
                        {t(route.name)}
                      </Typography>
                    }
                  />
                </ListItemButton>
            ) : (
              <GridItem sx={styles.menu} key={index}>
                <SupportMenu subitemRoutes={route.subitems} />
              </GridItem>
            )
          )}
        </List>
        <GridItem sx={styles.icons}>
          <TopMenu />
          <LanguageMenu />
        </GridItem>
      </GridContainer>
    </GridItem>
  )
}
export default AppBar
