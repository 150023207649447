export const routesAppBar = [
  {
    name: "Services",
    route: "/services",
  },
  {
    name: "Contact",
    route: "/contact",
  },
  {
    name: "About Us",
    route: "/about",
  },
]
